import request from '@http';

// 2022/06/06 王江毅 资产盘点列表
export function getAssetsBlitemList(params) {
    return request({
        method: 'GET',
        url: '/api/assets/blitem/list',
        params,
    });
}

// 2022/06/06 王江毅 新增盘点单
export function assetsBlitemCreateCheck(data) {
    return request({
        method: 'put',
        url: '/api/assets/blitem/createCheck',
        data,
    });
}

// 2022/06/06 王江毅 新增盘点单
export function assetsBlitemDel(uuid) {
    return request({
        method: 'get',
        url: '/api/assets/blitem/del/' + uuid,
    });
}

// 2022/06/06 王江毅 新增盘点单
export function assetsBlitemStatus(uuid) {
    return request({
        method: 'get',
        url: '/api/assets/blitem/status/' + uuid,
    });
}

// 2022/06/09 王江毅 盘点详情 /api/assets/blitem/detailList
export function blitemDetailList(params) {
    return request({
        method: 'get',
        url: '/api/assets/blitem/detailList',
        params,
    });
}

// 2022/06/09 王江毅 盘点详情 /api/assets/blitem/detailList
export function blitemPubCheck(data) {
    return request({
        method: 'PUT',
        url: '/api/assets/blitem/pubCheck',
        data,
    });
}

// 2022/06/09 王江毅 下载盘点报告 /api/assets/blitem/report/{uuid}
export function blitemReport(params) {
    return request({
        method: 'get',
        url: `/api/assets/blitem/export`,
        params,
        responseType: 'blob',
    });
}

// 2022/06/09 王江毅 下载盘点报告 /api/assets/blitem/details/868a629d251b4db5859026d023c2226c
export function blitemDetails(uuid) {
    return request({
        method: 'get',
        url: `/api/assets/blitem/details/${uuid}`
    });
}