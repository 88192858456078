<template>
    <section class="inventory">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-button size="small" type="primary" @click="openAdd">新建</el-button>
        </div>
        <div style="padding: 10px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentTableRef" :dataRequest="getAssetsBlitemList" :columns="inventoryTableColumn"
                       :query="formSearch" :height="750">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="details(row)">详情</span>
                            <span class="table-btn" @click="del(row)">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <dialog-add-inventory ref="dialogAddInventory" @handleSearch="handleSearch"/>
        <r-e-dialog title="盘点详情" :visible.sync="dialogVisible" :show-footer="false" top="5vh" width="600px">
            <el-form ref="formPublish" label-width="110px" size="small">
                <div class="flex">
                    <el-form-item label="盘点名称">
                        <el-input v-model="formSearch.name" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="所属公司">
                        <el-input v-model="formSearch.companyUuid" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="所属区域">
                        <el-input v-model="formSearch.region" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="管理员">
                        <el-input v-model="formSearch.creatAdmin" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="资产分类">
                        <el-input v-model="formSearch.assetsType" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="资产状态">
                        <el-input v-model="formSearch.assetState" disabled style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>
<script>
    import {inventoryTableColumn} from "@/views/property-management/assets-management/data";
    import {getAssetsBlitemList, assetsBlitemDel,assetsBlitemStatus,blitemReport,blitemDetails} from '@/api/inventory';
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    import {downloadByData} from "@/utils/hooks/download";
    import { timeFormat } from "@custom/index";
    export default {
        name: "inventory",
        data() {
            return {
                inventoryTableColumn: inventoryTableColumn(this),
                formSearch: {},
                dialogVisible:false,
            };
        },
        components: {
            dialogAddInventory: () => import("./components/dialog-add-inventory")
        },
        methods: {
            getAssetsBlitemList(params){
                return getAssetsBlitemList(params);
            },
            handleSearch(){
                this.$refs["rentTableRef"].pNumber = 1;
                this.$refs["rentTableRef"].getTableData();
            },
            openAdd() {
                this.$refs["dialogAddInventory"].openDialog();
            },
            del({uuid}){
                this.$confirm('此操作将删除该盘点,是否继续?', '注意', { type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    assetsBlitemDel(uuid).then(res => {
                        MessageSuccess("删除盘点成功");
                        loading.close();
                        this.handleSearch();
                    }).catch(err => {
                        MessageError("删除盘点失败");
                        loading.close();
                    });
                }).catch(() => {});
            },
            assetsBlitemStatus({uuid}){
                this.$confirm('此操作将修改该盘点单,是否继续?', '注意', { type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    assetsBlitemStatus(uuid).then(res => {
                        MessageSuccess("修改盘点单成功");
                        loading.close();
                        this.handleSearch();
                    }).catch(err => {
                        MessageError("修改盘点单失败");
                        loading.close();
                    });
                }).catch(() => {});
            },
            blitemReport({uuid,name}){
                blitemReport({blitemUuid:uuid}).then(res=>{
                    downloadByData({data: res, filename: name + `${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xlsx`});
                })
            },
            details({uuid}){
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                blitemDetails(uuid).then(res=>{
                    loading.close();
                    let {info}=res;
                    that.formSearch = info;
                    that.dialogVisible=true;
                }).catch(err => loading.close());
            }
        },
        async mounted() {
        },
        watch: {}
    }
</script>
<style lang="scss" scoped>
    .inventory {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>